import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import about1 from '../../resources/themeContent/images/theme-images/about2.jfif';
import about2 from '../../resources/themeContent/images/theme-images/about1.jfif';


const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

  return (
    <>


      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"}  />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About Us" />

      <section className="about-area ptb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <h2>About Our Store</h2>
                <p>
                Welcome to Theshopello, your ultimate destination for innovative health and beauty solutions!
<br/>
<br/>
At Theshopello, we believe that everyone deserves to look and feel their best without breaking the bank. That’s why we’re dedicated to bringing you carefully curated, high-quality products that help you embrace confidence and comfort in your daily routine. From wellness essentials to beauty must-haves, our selection is designed to simplify self-care and enhance your lifestyle.
                  </p>
                  <h4>What sets us apart?</h4>

                <p>
                Customer-Centric Approach: We prioritize your satisfaction and aim to make every shopping experience seamless and enjoyable.
                <br/>
                Global Reach: No matter where you are, our store is here to deliver top-tier products straight to your doorstep.
At Theshopello, your journey to self-confidence starts here. Join thousands of happy customers who trust us to bring out their best selves, one product at a time.
                </p>

                <p>
                Thank you for choosing us. Together, let’s make health and beauty accessible for everyone.
                </p>


              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={about1} className="about-img1" alt="image" />
                <img src={about2} style={{"width":"400px"}} className="about-img2" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>



      <BestFacilities />

    </>
  );

}

export default About;
